$(document).ready(function(){
    lib.showCategories('.menu2 li');

    // OPEN MENU SUBMENU ---------------
    $(".parents-toggle > a").click(function () {
        $(".parents-toggle > div.content-toggle").not($(this).siblings()).slideUp();
        $(this).siblings(".content-toggle").slideToggle();
    });

    filterOpen();

    $("div.content-toggle").first().slideDown();

});

$(window).resize(function() {
    filterOpen();
});

function filterOpen() {
    // Toggle Work Filter -------------
    //var $wFilter = $(".toggle-wfilter");
    var $wfilterTrigger = $(".wfilter-trigger");
    var $filterNav = $(".filter-nav");

    if (window.matchMedia("(max-width: 800px)").matches || window.matchMedia("(max-height: 600px)").matches) {
        $(".toggle-wfilter, .wfilter-trigger>a.cta-categories").unbind('mouseenter').unbind('mouseleave');

        $wfilterTrigger.click(function(){
            if ($filterNav.hasClass("opened")) {
                $filterNav.removeClass('opened');
            } else {
                $filterNav.addClass('opened');
            }
        });

    } else {
        $wfilterTrigger.unbind('click');

        $(".toggle-wfilter, .wfilter-trigger>a.cta-categories").hover(function(){
            $filterNav.addClass('opened');
        },function(){
            $filterNav.removeClass('opened');
        });
    }
}